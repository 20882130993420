
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'userReviewRules',
  components: {
  },
  mixins: [GlobalMixin]
})
export default class userReviewRules extends Vue {
  @Provide() public pact: any = {}

  public created () {
    this.refresh()
  }

  @Emit()
  private async refresh () {
    try {
      const res = await httpHelper.get({
        url: 'Pact' + '?sysno=57',
        type: 'apiv5'
      })
      this.pact = res.data
    } catch (err) {
      this.$toasted.show(err.msg)
    }
  }

  @Emit()
  private goback () {
    history.back()
  }
}

